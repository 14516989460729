

.inputStyle {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: "10px";
  }

  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }

 .radioButtonStyles {
    display: flex;
    flex-direction: column;
    border: "1px solid grey";
    border-radius: "4px";
    margin: "0px 12px"
  }

   .headingStyle {
    font-weight: 700;
  }

   .inputFieldStyle {
    display: flex;
    align-items: center;
    flex-wrap: wrap
  }

   .modificationStyle {
    display: flex;
    align-items: center
  }
