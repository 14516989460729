.dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value for opacity */
    z-index: 9999; /* Make sure it's above other elements */
    /* background-image: url('../assets/images/Feedback_BG.svg'); */
}
