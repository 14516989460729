
.history-container{
    height: 205vh;
    background-color: #edf0f5;
  }
  .version-history-container {
    margin: 20px 20px 20px 60px;
    padding-top: 30px;
    }
    
    .version-history-header {
      margin-bottom: 20px;
    }
    
    .version-entry {
      display: grid;
      grid-template-columns: 1.5fr 8.5fr;
      column-gap: 10px;
      margin-bottom: 20px;
    }
    
    @media screen and (min-width: 1500px) {
      .version-entry {
        grid-template-columns: 1.2fr 8.8fr;
      }
    }
    .filled-icon {
      font-size: 1.5em;
      cursor: pointer;
      margin-left: 27%;
    }
    .unfilled-icon {
      font-size: 1.5em;
      cursor: pointer;
      margin-left: 25%;
    }
    
    @media screen and (max-width: 600px) {
      /* Adjust the styles for smaller screens */
      .filled-icon {
        font-size: 1.2em;
        margin-left: 5px; /* Adjust the margin as needed */
      }
    }
    
    .expand-button {
      margin-left: auto; /* Moves the button to the right */
    }
    
    .date-column {
      /* No need for flex property in grid layout */
      
    }
    
    .info-column {
      /* No need for flex property in grid layout */
    }
    
    button {
      cursor: pointer;
    }
    
    .info-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      background-color: #ffffff; /* White background color */
      border-radius: 10px; /* Rounded corners */
      padding: 5px 20px 5px 20px; /* Adjust the padding top and bottom */
      border: 1px solid #e0e0e0;
      font-weight: "600px"
    }
    
    .additional-info {
      background-color: #ffffff; /* White background color */
      border-radius: 10px; /* Rounded corners */
      padding: 20px;
      /* Style additional information as needed */
      border: 1px solid #e0e0e0;
    }
    
    /* Style for the accordion */
    details {
      border: 1px solid #ddd;
      padding: 5px;
      border-radius: 4px;
      margin-top: 10px;
    }
    
    summary {
      cursor: pointer;
    }
    