body, html {
  margin: 0;
  padding: 0;
}

.ask-question-container {
  font-family: 'Verdana';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh !important;
  background-image: url('../assets/images/QuestionBG.png');
  background-size: cover; /* This ensures the image covers the entire container */
  background-color: #f5f5f5;
}

.ask-question-label {
   font-family: 'Verdana', sans-serif;
  font-size: 2.5em;
  margin-bottom: 30px; /* Add more space between label and search input */
}


.search-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 50%; /* Takes half of the screen */
  
}


.question-label {
  margin-bottom: 20px;
}
.search-field {
  flex: 1; /* Grow to take available space */
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 25px;
  background-color: #fff; /* White color for input field */
  margin-right: -20px;
}

.search-button {
  border: none;
  border-radius: 25px;
  background-color: #c51c1c;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  transition: filter 0.3s; /* Add transition for smooth effect */
}

.search-button.hovered {
  background-color: blue;
}