* {
    box-sizing: border-box;
}

  
  /* Add other styles for your login form or content */
  

.login_section {
 margin-left: 280px;
 margin-top: 140px;
 width: 40%;
 border: .5px solid #cccccc82;
 border-radius: 10px;
 padding: 20px;
 background: linear-gradient(to left, #00000040, #cccccc45); 

}

.empty-space {
    height: 20px; /* Adjust the height as needed */
  }

.inputField {
    border-radius: 12px;
    padding: 12px;
    margin: 10px;
    height: 54px;
    outline-style: none;
}
.partInputField {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px;
    height: 32px;
    outline-style: none;
    border: 1px solid #cbc3c3;
}

.conversionInputField {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px;
    width: 75px;
    height: 32px;
    outline-style: none;
    border: 1px solid #cbc3c3;
}

.conversionValue {
    width: 54px;
    border-radius: 8px;
    background: #EFEFEF;
    padding: 4px;
    text-align: center;
}

.subHeading {
    font-family: Verdana !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    width: 20% !important;
    color: #212F37 !important;
}

.narrowInput {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px 20px 10px 60px;
    height: 32px;
    width: 100px;
    outline-style: none;
    border: 1px solid #cbc3c3;
}

.narrow_input {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px 85px 10px 50px;
    height: 32px;
    width: 100px;
    outline-style: none;
    border: 1px solid #cbc3c3;
}
.wideInput {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px 0px;
    height: 32px;
    width: 50%;
    outline-style: none;
    border: 1px solid #cbc3c3;
}

.narrowValue {
    padding: 6px 10px;
    margin: 10px 20px !important;
    height: 32px;
    width: 100px;
    outline-style: none;
}

.smallNarrowValue {
    padding: 6px 10px;
    margin: 10px 20px 10px 65px !important;
    height: 32px;
    width: 100px;
    outline-style: none;
}

.partTextField {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px 0px;
    outline-style: none;
    border: 1px solid #cbc3c3;
}

.formikExtension {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* width: 35%; */
}

.formik {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.loginButton {
    width: 30%;
    border: 0px;
    border-radius: 32px;
    padding: 12px;
    margin: 10px;
    color: white;
    height: 58px;
    outline-style: none;
    background-color: #C6003D;
}

.submitButton{
    border: 0px;
    border-radius: 32px;
    padding: 10px 30px;
    margin: 10px;
    color: white;
    height: 44px;
    outline-style: none;
    background-color: #C6003D;
}

.cancelButton {
    border: 0px;
    border-radius: 32px;
    padding: 10px 30px;
    margin: 10px;
    color: #C6003D;
    height: 44px;
    outline-style: none;
    /* background-color: #C6003D; */
}

.text {
    color: white;
    font-family: 'Verdana';
}
.checkboxField {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 12px;
    font-weight: 300;
    margin: 10px;
    align-items: center;
    width: 50%;
}
.showpassword{
    left: 382px;
    position: relative;
    top: -50px;
}

.loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.headings {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-evenly;
    margin: 40px auto 0px;
}
 .creationHeading {
    display: flex;
    flex-direction: row;
    width: 53%;
    justify-content: space-around;
    margin: 20px 225px 0px 260px
 }
.valueDisplay {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.value_Display {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 100px
}

.conversionEqual {
    font-weight: 700;
    color: rgb(198, 0, 61);
    width: 30px;
    border: 1px solid #cbc3c3;
    text-align: center;
    border-radius: 6px;
    margin: 0px 12px;
    position: relative;
    left: 6px;
}

