.loader-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
}
.MuiCardContent-root {
    padding: 0 !important;
}
.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #C6003D transparent #C6003D transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    margin-top: -50px!important; /* Adjust this value as needed */
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Adjust spinner size for smaller screens */
@media (max-width: 768px) {
    .spinner {
        width: 32px; /* Adjust size for smaller screens */
        height: -100px; /* Adjust size for smaller screens */
        border-width: 4px; /* Adjust border width for smaller screens */
    }
}
