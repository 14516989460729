.insights-container {
    margin-top: 30px;
    padding-right: 20px;
}

.insights-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-height: 330px; /* Set a maximum height for the cards container */
  overflow-y: auto;
}

.insights-cards .MuiCard-root {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-left: 2px solid #ffa26c; 
    padding-left: 10px; 
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between cards as needed */
    padding: 10px; /* Add padding to space out the cards */
  }

