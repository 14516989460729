.dialogHeading {
    display: flex !important;
    flex-direction: row !important;
    align-Items: center !important;
    width: 50% !important;
    justify-content: space-evenly !important;
}

.equipmentNumber {
    width: 100% !important;
    font-family: Verdana !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    margin: 20px 0px !important;
    color: #212F37 !important;
}

.actionButton {
    border-radius: 30px !important;
    text-transform: capitalize !important;
    border: 1px solid #c6003d !important;
    cursor: pointer !important;
    z-index: 1000 !important;
}

.actionButtonName {
    color: #2D3748 !important;
    font-family: Verdana !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #c6003d !important;
    width: 80px !important;
    border: 0px !important;
    height: 36px !important;
    padding: 4px !important;
}

.searchInputField {
    width: calc(100% - 78px) !important;
    padding: 10px 75px 10px 50px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    border-radius: 25px !important;
    font-family: Verdana !important;
    border-width: 1px !important;
    border-color: #ccc !important;
    border-style: solid !important;
    padding-left: 50px !important;
    background-color: #f5f5f5 !important;
    height: 40px !important;
    /* margin-left: 40px!important; */
}

.buttonIcon {
    position: relative !important;
    left: 45px !important;
    top: -1px !important;
    z-index: 2 !important;
    font-size: 10px !important;
}

.approval {
    font-family: Verdana !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin: 30px 0px !important
}

.selectHeading {
    font-family: Verdana !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.selectOption {
    display: flex !important;
    flex-direction: column !important;
    align-items: baseline !important;
}

.datePickerLabel {
    font-family: Verdana!important;
    font-size: 14px!important;
    font-weight: 400!important;
    text-align: center!important;
}
.datePickerInput {
    height: 36px!important;
    padding: 0px 28px!important;
    /* width: 75%!important; */
    border-radius: 8px!important;
    border: 1px rgba(102, 108, 130, 0.33) solid !important;
}
.datePickerIcon {
    position: absolute!important;
    top: 10px!important;
    left: 10px!important;
}
.headers {
    display: flex;
    align-items: center;
    background: #FFFFFF;
}