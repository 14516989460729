
.dashboard-container {
  display: flex;
  /* background-color: #edf0f5; */
}

.left-panel, .right-panel {
  padding-left: 30px; /* Add padding */
  box-sizing: border-box; /* Ensure padding doesn't increase the width */
}

.left-panel {
  flex: 6.5;
  /* Add any additional styles you need for the left panel */
}

.right-panel {
  flex: 3.5;
  /* Add any additional styles you need for the right panel */
}

.box {
  width: 100%;
  height: 100px;
  background-color: #ccc; 
}

.search-result-container {
  width: 100%;
  padding: 10px; /* Add padding */
  box-sizing: border-box; /* Ensure padding doesn't increase the width */
}

::-webkit-scrollbar  {

  height: 0.5rem;

  width: 0.5rem;

}
 ::-webkit-scrollbar-thumb  {

  background: #C6003D;

  border-radius: 2px;

}

 ::-webkit-scrollbar-track {

  background: #dee5ed94;

}

.select-container {

}

.select-container .MuiSelect-root {
  
}

.select-container .MuiSelect-root::-webkit-scrollbar {
  width: 12px; 
}

.select-container .MuiSelect-root::-webkit-scrollbar-thumb {
  background-color: #C6003D; 
  border-radius: 6px; 
}

.select-container .MuiSelect-root::-webkit-scrollbar-track {
  background: #dee5ed94;
}

.select-container .MuiSelect-root::-webkit-scrollbar-thumb:hover {
  background: #dee5ed94;
}

.fixed-column {
  width: 200px;
  /* position: sticky !important; */
  left: 0;
  background-color: rgb(253, 249, 249) !important;
  box-shadow: 6px 0px 6px -4px rgba(0, 0, 0, 0.1) !important; /* Adjust the shadow values */
  z-index: 1000 !important;
  margin-top: -10px; /* Adjust the margin to lift it higher */
}
.footer {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 30px;
  background-color:"#edf0f5"
}

.version-button {
  /* Define your button styles here */
  color: #000;
  font-family: Verdana;
  color: rgba(0, 0, 0, 0.87); /* White text color */
  padding: 10px 20px; /* Padding for the button */
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer; /* Add pointer cursor on hover */
  font-weight: 500;
}




