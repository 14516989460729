.dialogContent {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}

.row {
    width: 50%;
}

.elementRow {
    display: flex;
    padding: 4px;
    width: 100%;
    margin: 4px;
    font-size: 12.5px;
    border-bottom: 1px solid #DDE2E5;
    word-wrap: break-word !important;
}

.rowelement {
    display: flex;
    padding: 4px;
    width: 100%;
    margin: 4px;
    font-size: 12.5px;
    /* border-bottom: 1px solid #DDE2E5; */
    word-wrap: break-word !important;
}

.rowElement {
    display: flex;
    padding: 4px;
    width: 80%;
    margin: 4px;
    font-size: 12.5px;
    justify-content: center;
    word-wrap: break-word !important;
}

.elementHeading {
    font-family: Verdana;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    width: 40%;
    
}
.element {
    font-family: Verdana;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    width: 50%;
    
}