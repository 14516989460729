.thumbs,
.thumbsUp {
  margin-right: 20px;
  cursor: pointer;
  font-size: 20px;
  transition: transform 0.2s;
}

.thumbsUp {
  margin-left: 20px;
}

.thumbsUp.active,
.thumbsDown.active {
  color: rgb(198, 0, 61);
  transform: scale(1.2);
}

.search-result-container {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: calc(100vh - 20px);
}

.search-result-container::-webkit-scrollbar {
  width: 12px;
}

.search-result-container::-webkit-scrollbar-thumb {
  background-color: #c6003d;
  border-radius: 4px;
}

.search-result-container::-webkit-scrollbar-track {
  background: #dee5ed94;
}

/* Define initial styles for thumbs-up and thumbs-down */
.thumbs-up,
.thumbs-down {
  display: inline-block;
}

/* Define styles for thumbs-up and thumbs-down when clicked */
.thumbs-up-clicked,
.thumbs-down-clicked {
  display: inline-block;
  animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


.table-container {
  display: flex;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1000;
  width: 200px; /* Adjust the width as needed */
}


.popOverDialog {
  width: 220px;
  height: 350px;
  border-radius: 8px;
}

.checkbutton {
  background-color: #EFEFEF;
  color: #C6003D;
  border: 0px;
  font-family: Verdana;
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  height: 35px;
  border-radius: 8px;
  margin: 10px;
  cursor: pointer;
}

.ApplyButton {
  width: 100px;
  border: 0px;
  border-radius: 12px;
  margin: 10px;
  color: white;
  height: 35px;
  outline-style: none;
  background-color: #C6003D;
}

.searchInput {
  width: 210px;
  margin: 4px;
  height: 40px;
  border: 0px;
  padding-left: 40px 
}

.fixed-bottom {
  position: sticky;
  bottom: 0;
  width: 220px;
  background-color: #FFFFFF;
  padding: 2px;
  z-index: 1000;
  text-align: center;
}
