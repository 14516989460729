.plantCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    align-items: center
}

.plantName {
    color: #212F37 !important;
    font-family: Verdana !important;
    font-size: 24px !important;
    font-weight: 400 !important;
}

.plantHead {
    font-family: Verdana !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: center !important;
    color: #212F37 !important
}

.checkPart {
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.checkDecisionPart {
    display: flex;
    justify-content: space-around;
    width: 50%;
}

.checkbutton {
    background-color: #EFEFEF;
    color: #C6003D;
    border: 0px;
    font-family: Verdana;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    margin: 10px;
    position: relative;
    top: 25px;
    cursor: pointer;
}

.partCreationInputField {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 10px;
    height: 32px;
    outline-style: none;
    border: 1px solid #cbc3c3;
}

.formikPartCreation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px;
}

.pirdata {
    font-family: Verdana !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    text-align: left !important;
    margin: 20px !important;
}

.substituionName {
    font-family: Verdana !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    margin: 10px !important;
}

.buttonCancel {
    /* width: 100px; */
    /* height: 30px; */
    border: 1px solid #BA0C2F;
    border-radius: 20px;
    color: #BA0C2F;
    /* position: relative;
    top: 35px;
    left: 25px; */
    /* font-size: 18px !important; */
    font-weight: 600 !important;
}

.buttonBack {
    display: flex;
    flex-direction: row;
    width: 100px;
    align-items: center;
    justify-content: center;
    border: 1px solid #BA0C2F;
    border-radius: 20px;
    font-size: 18px;
    color: #BA0C2F !important;
    background-color: transparent;
    height: 40px;
}

.sentApproval {
    font-family: Verdana !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    text-align: center !important;
}

.substitution {
    font-family: Verdana !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-align: center !important;
}

.totalSubstitution {
    font-family: Verdana !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #212F37 !important;
    background: #EFEFEF !important;
    border-radius: 20px !important;
    padding: 4px 10px !important;
    width: 250px !important;
    margin: 10px !important;
}

.extendPart {
    border: 1px solid #BA0C2F;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    color: #BA0C2F;
    background: transparent;
    font-weight: 500;
    cursor: pointer;
}

.partDesc {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    /* width: 75%; */
}

.shortDesc {
    width: 100% !important;
    background: #EFEFEF !important;
    padding: 4px 10px !important;
    margin: 10px !important;
    border-radius: 8px !important;
}

.mainHeading {
    display: flex;
    flex-direction: row;
    width: 50%;
    margin: 21px 90px;
    /* margin: 21px 280px; */
    justify-content: space-evenly;
}

#combo-box-demo {
    width: 200px;
    position: relative;
    top: -10px
}

.selectComponent .MuiInputBase-root {
    height: 35px;
    border: 1px solid #BDBDBD;
    border-radius: 8px;
    padding: 10px
}