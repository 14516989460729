.header-container {

  top: 0; /* Position it at the top of the viewport */
  width: 100%; /* Make it take up the full width */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 0px 20px;
  background-color: #fff;
  color: #333;
}

.logo-container {
  flex: 1;
}

.logo {
  width: 90px;
  margin-right: 10px; /* Add some spacing between logo and text */
  cursor: pointer;
}

.page-title {
  display: flex;
  align-items: center;
flex-grow: 7;
  /* align-items: center; */
}

.header_tabs {
  display: flex;
  align-items: center;
}

.header-tab {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 20px; /* Adjust margin as needed */
  position: relative;
}

.header-tab.active::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Adjust the distance of the border from the text */
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #C6003D; /* Set the border color */
}

.icon {
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
  margin-right: 15px;
}

.icon-text {
  color: #000000!important;
  font-family: "Verdana !important";
  font-size: 15px !important;
  font-weight: 600 !important;
  margin-left: 5px; /* Adjust margin as needed */
  cursor: pointer;
}


.user-info {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.user-image {
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.user-name {
  font-size: 1em;
  font-weight: bold;
}

.icon-container {
  display: flex;

}

.icon {
  font-size: 1.5em;
  /* margin-left: 20px; */
  cursor: pointer;
}

.bell-icon {
  font-size: 1.5em;
  /* margin-left: 20px; */
  cursor: pointer;
  top: "-10px";
  margin-left: -50px; 
  /* margin-top: 5px */
}

.message-icon {
  font-size: 1.5em;
  /* margin-left: 20px; */
  cursor: pointer;
  top: "-10px";
  margin-top: 7px
}

.user-icon {
  font-size: 1.5em;
  /* margin-left: 20px; */
  cursor: pointer;
  top: "-10px";
  margin-top: 3px;
  border: 1px solid #F2F2F2;
  padding: -4px;
  height: 28px;
  width: 28px;
  padding: 4px;
  border-radius: 50px;
}

.theme-icon {
  font-size: 1.5em;
  /* margin-left: 20px; */
  cursor: pointer;
  top: "-10px";
  margin-top: 6px;
  margin-right: -20px; 
}

.arrow-icon {
  font-size: 1.5em;
  /* margin-left: 20px; */
  cursor: pointer;
  top: "-10px";
  margin-top: 5px
}

.icons-container {
  display: flex;
  width: 400px;
  justify-content: space-around;
  align-items: flex-start;
}

.header_heading {
  width: 300px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.header_icon  {
  border-bottom: 3px solid #C6003D;
  /* border-radius: 4px; */
  /* padding: 4px; */
}

.userHeading {
  display: flex;
  flex-direction: row;
}

.userSubHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-button{
    border: none;
    border-radius: 25px;
    border:  1px solid #c51c1c;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    background: transparent;
    transition: filter 14s; /* Add transition for smooth effect */
    margin-top: -4px;
    font-family: "Verdana !important",
}





